@import '../font-awesome/css/_font-awesome.min';
@import 'reset';
@import 'breaks';
@import 'mixins';

body {
  width: 100%;
  height: 100%;
  position: absolute;
  font-family: 'Roboto', sans-serif;
  background: #f5f5f5;
}

header {
  @include size(100%, 100%);
  float: left;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  @include for-phone-only {
  }
}

#defaultCanvas0 {
  position: absolute;
  @include size(100%, 100%);
  top: 0px;
  bottom: 0px;
  z-index: -1;
  margin: 0;
  padding: 0;
  @include for-phone-only {
  }
}

h1 {
  color: #eb4c82;
  font-size: 3em;
  margin-bottom: 7%;
}

.bio-container {
  text-align: center;
  max-width: 800px;

  .bio {
    color: #413887;
    font-size: 1.8em;
    margin-bottom: 8%;
  }
  .skills {
    color: #2d9cdb;
    margin-bottom: 10%;
  }
  .social {
    a {
      text-decoration: none;
      margin-right: 2%;
    }
    .social-icon {
      color: #333333;
    }
  }
}

.social {
  /*width: 100%;
	height: 20%;
	background: #58E9CC;
	display: flex;
	justify-content: space-around;
	align-items: center;*/
}
